<template>
  <div class="static-content-wrapper">
    <h1>Pricing</h1>
    <p>
      We are currently in BETA release with a limited number of participants.
      Pricing has not been set yet, but we anticipate a monthly and yearly
      pricing model.
    </p>
    <p>
      If you would like to help us determine the price, simply
      <a class="secondary" href="mailto:gkruel@me.com" target="_blank"
        >send an email</a
      >
      stating how much you would pay per month or per year. Your participation
      and honesty will help us greatly.
    </p>
  </div>
</template>
